


































import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import RouteHelper from '@/utils/helpers/RouteHelper';

@Component({
  inheritAttrs: false,
})
export default class StaticBannerWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({
    required: false,
    default: null,
  })
  private readonly desktopImage!: { path: string; id: number };

  @Prop({
    required: false,
    default: null,
  })
  private readonly tabletImage!: { path: string; id: number };

  @Prop({
    required: false,
    default: null,
  })
  private readonly mobileImage!: { path: string; id: number };

  @Prop({
    required: false,
    default: null,
  })
  private readonly route!: string;

  private get banner(): { image: { path: string; id: number }; url: string } {
    // eslint-disable-next-line no-nested-ternary
    return this.isDesktop
      ? {
        image: { ...this.desktopImage },
        url: this.$t(this.route) as string,
      }
      : this.isTablet
        ? {
          image: { ...this.tabletImage },
          url: this.$t(this.route) as string,
        }
        : {
          image: { ...this.mobileImage },
          url: this.$t(this.route) as string,
        };
  }

  created(): void {
    this.setDataConfig();
  }

  private navigateTo(url: string): void {
    RouteHelper.universalNavigation(
      url,
      this.$route.params,
      this.$router,
      undefined,
    );
  }
}
